<template>
  <div>
    <div class="vs-row hero">
      <div class="hero-text self-center sm:mx-20 mx-2">
        <p class="text-5xl font-bold pl-4">Notifications</p>
        <p class="px-4 text-lg">Lorem ipsum, dolor sit amet, consectetur adipisicing elit, sed</p>
        <vs-breadcrumb :items="items" separator="chevron_right"></vs-breadcrumb>
      </div>
    </div>
    <div class="mx-10">
      <div class="mt-10">
        <div class="vs-row sm:pb-16 pb-24">
          <div class="vs-col sm:w-1/2 w-full">
            <p class="text-2xl font-bold">Notifications</p>
          </div>
          <div class="vs-col sm:w-1/2 w-full flex justify-end">
            <vs-button class="btn" @click="removeAllNotification()" color="danger" text-color="#fff" icon="delete">Remove All</vs-button>
          </div>
        </div>
      </div>
      <div class="border px-3 py-3 mb-3" v-for="(item, index) in notifications" :key="item.index" :offset="index > 0 ? 2 : 0">
        <p>{{ item.title }}</p>
        <div class="flex justify-end">
          <p class="text-gray-400">{{ item.date }}</p>
          <span class="px-2 text-red-600 flex">
            <vs-icon size="20px" class="self-center" icon="delete"></vs-icon>
            <span class="self-center cursor-pointer" @click="removeNotification(item)">Remove</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios.js'

export default {
  data () {
    return {
      items: [
        {
          title: 'Home',
          url: 'home',
        },
        {
          title: 'Profile',
          url: 'profile',
        },
        {
          title: 'Notifications',
          active: true
        }
      ],
      notifications: []
    }
  },
  methods: {
    removeNotification (data) {
      this.$vs.loading()
      axios.post('v1/deleteNotification', {id: data.id}, {headers: this.headers})
      .then((response) => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          this.loadNotifications()
          this.$vs.loading.close()
        }
      })
    },
    loadNotifications () {
      this.$vs.loading()
      axios.post('v1/getNotificationLog', {}, {headers: this.headers})
      .then(response => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          this.notifications = []
          this.notifications = response.data.payload
          this.$vs.loading.close()
        }
      })
    },
    removeAllNotification () {
      this.$vs.loading()
      axios.post('v1/deleteNotificationLog', {}, {headers: this.headers})
      .then((response) => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          this.notifications = []
          this.$vs.loading.close()
        }
      })
    },
    openActivityView (item) {
      this.$router.push({
        name: 'activity-details',
        params: {
          activityID: item.id
        }
      })
    }
  },
  created () {
    this.loadNotifications()
  }
}
</script>

<style scoped>
.hero {
  background-image: url('../assets/images/footer.jpg');
}
.btn {
  padding: 7px 20px;
  border-radius: 5px;
}
</style>